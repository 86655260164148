<!-- Localized -->
<template>
  <div class="app-settings flex items-start gap-5">
    <Block
      :title="$t('pages.theme_admin.theme_admin')"
      class="w-80 max-h-full"
      contentClass="flex flex-col gap-3 overflow-auto"
    >
      <div class="settings-block p-4 rounded-lg bg-gray-100" :class="[!checkPermission('theme.edit') && 'opacity-50 pointer-events-none cursor-not-allowed' ]" >
        <LogoInput
          :tenant_id="getTenantId"
          v-model="logo"
          :value="getThemeLogo"
          @input="saveLogo"
          :logo="getThemeLogo"
          @removeLogo="removeLogo"
          :settings="{
            disabled:!checkPermission('theme.edit')
          }"
        />
      </div>
      <label class="useDefault flex items-center gap-2">
        <Checkbox
          :disabled="!checkPermission('theme.edit')"
          class="useDefault__checkbox"
          v-model="useDefault"
          @input="setUseDefault"
        />
        <span class="text-base-content text-xs font-semibold cursor-pointer">
          {{ $t('pages.theme_admin.use_default_system_settings') }}
        </span>
      </label>
      <div
        class="settings-block p-4 rounded-lg bg-gray-100 flex flex-col gap-3 overflow-auto scroll-bar"
        :class="{'cursor-not-allowed': useDefault}"
      >
        <ColorPicker
          v-for="(color, key) in colors"
          :key="key"
          :class="{'opacity-70': useDefault}"
          :name="color.name"
          :value="color.value"
          @toggle="handleColor(color)"
          :isOpen="activeColor === color.var"
          :disabled="useDefault || !checkPermission('theme.edit')"
          @change="applyColor(key, $event)"
        />
      </div>
      <div class="flex justify-between gap-1">
        <Button
          class="w-24"
          :text="$t('actions.cancel')"
          @click="cancelColors"
          :disabled="!checkPermission('theme.edit')"
        />
         <Button
          class="w-24 bg-blue-500 border-blue-500 focus:bg-blue-600 active:bg-blue-600  hover:bg-blue-600 hover:border-blue-600"
          type="secondary"
          :text="$t('actions.save')"
          :disabled="!checkPermission('theme.edit')"
          @click="saveColors"
        />
      </div>
    </Block>
    <Block
      class="flex-1 self-stretch"
      contentClass="flex items-center justify-center text-gray-500"
    >
      <Preview /> 
    </Block>
  </div>
</template>

<script>
import Block from '@shared/components/block-container';
import ColorPicker from '@/components/color-picker';
import Checkbox from "@/components/checkbox";
import Button from "@/components/button";
import LogoInput from './components/ImageUploader';
import { mapActions, mapGetters, mapMutations } from "vuex";
import { checkPermission } from "@shared/utils/functions"
import Preview from "./components/preview"
import axios from "@/axios"
/* 
  Color sets:
    - getDefaultColors - hardcoded default colors.
    - getTenantConfig.uiconfig.color_config - colors from server.
    - getThemeColors - Colors applied on the frontend.
  Store: tenant-app/src/modules/theme/store.js

  On init:
    - reset_flag:
      - true:
        - 'Use default system settings' = true.
        - Parse colors from server.
        - Use these colors as default.
      - false:
        - 'Use default system settings' = false.
        - Parse hardcoded colors.
        - These colors are default.

  Actions:
    - 'Apply' color - set new color value in FRONTEND config (not default).
    - 'Cancel' color - reset color to its applied on the frontend value.
    - 'Save' - send all applied colors & logo to the API; reset colors to default values if useDefault.
    - 'Cancel' - reset all colors & logo to saved (server) values.
*/

// TODO Don't use default colors from store - just skip colors which values hasn't been set
// TODO as their default values are already set at @shared/assets/styles/style.scss

export default {
  name: 'theme-admin',
  components: {
    Block,
    ColorPicker,
    Checkbox,
    Button,
    Preview,
    LogoInput,
  },
  data() {
    return {
      useDefault: false,
      colors: null,
      logo: '',
      activeColor: ''
    }
  },
  created() {
    this.logo = this.getThemeLogo;
    this.useDefault = this.getThemeUseDefault;
    this.setColorsFromFrontend();
  },
  title: "Theme admin",
  computed: {
    ...mapGetters([
      'getTenantConfig',
      'getThemeColors',
      'getDefaultColors',
      'getThemeLogo',
      'getTenantId',
      'getThemeUseDefault',
      'getLoginTenantDetails'
    ]),
  },
  methods: {
    checkPermission,
    ...mapActions(['setTenant', 'setThemeLogo']),
    ...mapMutations([
      // 'SET_THEME_COLORS',
      'SET_THEME_COLOR',
      'SET_THEME_LOGO',
      'SET_TENANT_CONFIG',
      'SET_THEME_USE_DEFAULT',
    ]),
    setUseDefault(payload) { 
      this.SET_THEME_USE_DEFAULT(payload);
      this.setColorsFromFrontend();
    },
    // use to populate component's local colors with values received from the server:
    setColorsFromServer() {
      const useDefault = this.useDefault;
      this.colors = Object.entries(this.getTenantConfig.uiconfig.color_config)
        .reduce((result, [key, value]) => {
            result[key] = {
              ...this.getThemeColors[key],
              value: useDefault ? this.getDefaultColors[key].value : value,
            }
            return result;
        }, {});
    },
    // use to populate component's local colors with temporary frontend colors' values:
    setColorsFromFrontend() {
      const useDefault = this.useDefault;
      this.colors = Object.entries(this.getThemeColors)
        .reduce((result, [key, value]) => {
          if (key in this.getTenantConfig.uiconfig.color_config) {
            result[key] = {
              ...this.getThemeColors[key],
              // if useDefault use default colors; else use color saved on the frontend if exist: 
              value: useDefault ? this.getDefaultColors[key].value : (value.value || this.getDefaultColors[key].value),
            }
          }
          return result;
        }, {});
    },
    applyColor(color, value) {
      this.colors[color].value = value;
      this.SET_THEME_COLOR({color, value});
    },
    // use to reset all applied on the frontend colors with local colors from this component:
    applyCurrentColors() {
      Object.entries(this.colors).forEach(([color, value]) => {
        this.applyColor(color, value.value);
      });
    },

    handleColor(color) {
      this.activeColor = color.var
    },
    async updateConfig(logo)
    {
      try {
        await this.setTenant({
            ...this.getTenantConfig,
            logo_url: logo
        });
      } catch (error) {
        this.$toast.error(this.$t('pages.theme_admin.failed_to_save'));
      }
    },
    async saveLogo(logo) {
        await this.updateConfig(logo);
        this.setThemeLogo(logo);
        this.$toast.success(this.$t('pages.theme_admin.the_logo_has_been_uploaded_successfully'));
    },
    async saveColors() {
      try {
        this.SET_THEME_LOGO(this.logo);
        // this.SET_THEME_COLORS(this.colors);
        const { uiconfig } = this.getTenantConfig;
        const { color_config } = uiconfig;
        // reset color if has been changed:
        const newColors = Object.entries(color_config).reduce((result, [key, value]) => {
          result[key] = this.getThemeColors[key].value || value;
          return result;
        }, {})
        const newConfig = {
          ...this.getTenantConfig,
          logo_url: this.getThemeLogo,
          reset_flag: this.useDefault,

          uiconfig: {
            ...uiconfig,
            color_config: newColors,
          }
        }
        this.SET_TENANT_CONFIG({...newConfig});
        // if (this.useDefault) {
        //   delete newConfig.uiconfig;
        // } 
        await this.setTenant(newConfig);
        this.setColorsFromFrontend();
        // apply default colors' values on the frontend:
        if (this.useDefault) this.applyCurrentColors();
        this.$toast.success(this.$t('pages.theme_admin.saved_successfully'));
      } catch (error) {
        this.$toast.error(this.$t('pages.theme_admin.failed_to_save'));
      }
    },
    cancelColors() {
      // reset all frontend changes in config and colors with values from the server:
      this.SET_TENANT_CONFIG(this.getTenantConfig);
      this.setColorsFromServer();
    },
    async removeLogo(file_logo) {
      try {
        await axios.delete(`/tenant/logo/${this.getTenantId}`,{data: {
          logo_url: file_logo
        }})

        let configs = this.getTenantConfig;
        configs.logo_url = "";
        this.setThemeLogo("");
        this.logo = "";
        this.SET_TENANT_CONFIG(configs);
        this.updateConfig("");
        this.$toast.success(this.$t('pages.theme_admin.the_logo_has_been_removed_successfully'));
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.useDefault {
  &__checkbox {
    ::v-deep {
      .input-wrapper {
        display: flex;
      }
    }
  }
}
</style>